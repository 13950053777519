import '../css/app.css';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';

Alpine.plugin(persist);

Alpine.store('app', {
  mobile: false,
  syncDates() {
    document.getElementById('clientDate').value = document.getElementById('clientDateHead').value;
    document.getElementById('clientMeetingDate').value =
      document.getElementById('clientMeetingDateHead').value;
  },
});

Alpine.start();
